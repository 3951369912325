import { useEffect, useRef } from "react";
import axios, { AxiosError, AxiosResponse } from "axios";
import { Arguments, MutatorCallback, MutatorOptions } from "swr";
import useSWRInfinite from "swr/infinite";

import { CarriersListResponse } from "models/dto/CarriersListResponse";
import { QueryGridResponse } from "models/QueryGridResponse";
import { CarriersFilter } from "./useCarriersFilter";

const pageSize = 25;

// SWR does not export these which is making it a PITA to type
type SWRInfiniteRevalidateFn<Data> = (data: Data, key: Arguments) => boolean;

interface SWRInfiniteMutatorOptions<Data, MutationData = Data>
  extends Omit<MutatorOptions<Data, MutationData>, "revalidate"> {
  revalidate?:
    | boolean
    | SWRInfiniteRevalidateFn<Data extends unknown[] ? Data[number] : never>;
}

export type InfiniteKeyedMutator<Data> = <MutationData = Data>(
  data?: Data | Promise<Data | undefined> | MutatorCallback<Data>,
  opts?: boolean | SWRInfiniteMutatorOptions<Data, MutationData>
) => Promise<Data | MutationData | undefined>;

const useGetCarriersInfinite = ({
  cancelRequest,
  carriersFilter,
}: {
  cancelRequest?: boolean;
  carriersFilter: CarriersFilter;
}): {
  carriers: CarriersListResponse[];
  totalCarriers: number;
  isLoading: boolean;
  getNextPage: () => void;
  reloadCarriers: InfiniteKeyedMutator<
    QueryGridResponse<CarriersListResponse>[]
  >;
} => {
  const abortController = useRef<AbortController>();
  const baseUrl = "/api/carriers/list";

  useEffect(() => {
    if (cancelRequest && abortController.current) {
      abortController.current.abort();
    }
  }, [cancelRequest]);

  const getKey = (
    page: number,
    previousPageData: QueryGridResponse<CarriersListResponse>
  ) => {
    if (previousPageData && !previousPageData.entities.length) {
      return null;
    }

    const params = new URLSearchParams({
      page: (page + 1).toString(),
      pageSize: pageSize.toString(),
      sortField: carriersFilter.sortField,
      dir: carriersFilter.dir,
    });

    if (carriersFilter.search) {
      params.append("search", carriersFilter.search.trim());
    }

    if (carriersFilter.myCarriers) {
      params.append("myCarriers", "true");
    }

    if (carriersFilter.myFavoriteCarriers) {
      params.append("myFavoriteCarriers", "true");
    }

    if (carriersFilter.accountOwner) {
      params.append("accountOwner", carriersFilter.accountOwner.value);
    }

    carriersFilter.statuses.forEach((status) =>
      params.append("statuses", status)
    );

    carriersFilter.domicileStates.forEach((domicileState) =>
      params.append("domicileStates", domicileState)
    );

    carriersFilter.domicileCities.forEach((domicileCity) =>
      params.append("domicileCities", domicileCity)
    );

    carriersFilter.classifications.forEach((classification) =>
      params.append("classifications", classification)
    );

    carriersFilter.equipmentTypes.forEach((equipmentType) =>
      params.append("equipmentTypes", equipmentType.text)
    );

    carriersFilter.trailerTypes.forEach((trailerType) =>
      params.append("trailerTypeIds", trailerType.key.toString())
    );

    carriersFilter.preferredServiceAreas.forEach((serviceArea) =>
      params.append("preferredServiceAreas", serviceArea)
    );

    carriersFilter.attributes.forEach((attribute) =>
      params.append("attributes", attribute.key)
    );

    return `${baseUrl}?${params.toString()}`;
  };

  const swr = useSWRInfinite<
    QueryGridResponse<CarriersListResponse>,
    AxiosError
  >(
    getKey,
    (url: string) => {
      abortController.current = new AbortController();

      return axios
        .get<
          QueryGridResponse<CarriersListResponse>,
          AxiosResponse<QueryGridResponse<CarriersListResponse>>
        >(url, { signal: abortController.current.signal })
        .then((response) => response.data);
    },
    {
      keepPreviousData: false,
      revalidateOnFocus: true,
      shouldRetryOnError: false,
      revalidateFirstPage: false,
      revalidateOnMount: true,
    }
  );

  return {
    carriers: swr.data?.flatMap((data) => data.entities) ?? [],
    totalCarriers: swr.data?.[0].filteredCount ?? 0,
    isLoading:
      swr.isLoading ||
      (!swr.error && swr.size > 0 && !swr.data?.[swr.size - 1]),
    getNextPage: () => {
      swr.setSize(swr.size + 1);
    },
    reloadCarriers: swr.mutate,
  };
};

export default useGetCarriersInfinite;
