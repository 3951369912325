import { Dispatch, SetStateAction } from "react";
import { Flag, FlagNameValues } from "semantic-ui-react";

import { CarriersFilter } from "hooks/useCarriersFilter";
import CarrierFilterLabelPopup from "./CarrierFilterLabelPopup";
import { formatClassification } from "components/CarrierList/List/utils/carrierListUtilities";

import styles from "./CarrierFilterBar.module.scss";

type CarrierFilterLabelsProps = {
  carriersFilter: CarriersFilter;
  setCarriersFilter: Dispatch<SetStateAction<CarriersFilter>>;
};

const CarrierFilterLabels = (props: CarrierFilterLabelsProps) => {
  const resetField = (partialFilter: Partial<CarriersFilter>) => {
    props.setCarriersFilter({ ...props.carriersFilter, ...partialFilter });
  };

  return (
    <div className={styles.labelsContainer}>
      <div className={styles.filterTitle}>Filters:</div>

      {props.carriersFilter.search ? (
        <CarrierFilterLabelPopup
          labelText={`Search: ${props.carriersFilter.search.trim()}`}
          title="Remove Search"
          resetField={() => resetField({ search: "" })}
        >
          {props.carriersFilter.search.trim()}
        </CarrierFilterLabelPopup>
      ) : null}

      {props.carriersFilter.myCarriers ? (
        <CarrierFilterLabelPopup
          labelText="My Carriers"
          title="Remove My Carriers"
          resetField={() => resetField({ myCarriers: false })}
        >
          My Carriers
        </CarrierFilterLabelPopup>
      ) : null}

      {props.carriersFilter.myFavoriteCarriers ? (
        <CarrierFilterLabelPopup
          labelText="My Favorite Carriers"
          title="Remove My Favorite Carriers"
          resetField={() => resetField({ myFavoriteCarriers: false })}
        >
          My Favorite Carriers
        </CarrierFilterLabelPopup>
      ) : null}

      {props.carriersFilter.statuses.length ? (
        <CarrierFilterLabelPopup
          labelText="Status"
          title="Remove Statuses"
          resetField={() => resetField({ statuses: [] })}
        >
          {props.carriersFilter.statuses.map((status) => (
            <div key={status}>{status}</div>
          ))}
        </CarrierFilterLabelPopup>
      ) : null}

      {props.carriersFilter.domicileStates.length ? (
        <CarrierFilterLabelPopup
          labelText="Domicile State"
          title="Remove Domicile States"
          resetField={() => resetField({ domicileStates: [] })}
        >
          {props.carriersFilter.domicileStates.map((domicileState) => {
            const [country, state] = domicileState.split(",");

            return (
              <div key={domicileState}>
                <Flag name={country.toLowerCase() as FlagNameValues} />
                {state}
              </div>
            );
          })}
        </CarrierFilterLabelPopup>
      ) : null}

      {props.carriersFilter.domicileCities.length ? (
        <CarrierFilterLabelPopup
          labelText="Domicile City"
          title="Remove Domicile Cities"
          resetField={() => resetField({ domicileCities: [] })}
        >
          {props.carriersFilter.domicileCities.map((domicileCity) => (
            <div key={domicileCity}>{domicileCity}</div>
          ))}
        </CarrierFilterLabelPopup>
      ) : null}

      {props.carriersFilter.accountOwner ? (
        <CarrierFilterLabelPopup
          labelText={`Account Owner: ${props.carriersFilter.accountOwner?.text}`}
          title="Remove Account Owner"
          resetField={() => resetField({ accountOwner: null })}
        >
          {props.carriersFilter.accountOwner?.text}
        </CarrierFilterLabelPopup>
      ) : null}

      {props.carriersFilter.classifications.length ? (
        <CarrierFilterLabelPopup
          labelText="Classification"
          title="Remove Classifications"
          resetField={() => resetField({ classifications: [] })}
        >
          {props.carriersFilter.classifications.map((classification) => (
            <div key={classification}>
              {formatClassification(classification)}
            </div>
          ))}
        </CarrierFilterLabelPopup>
      ) : null}

      {props.carriersFilter.equipmentTypes.length ? (
        <CarrierFilterLabelPopup
          labelText="Equipment"
          title="Remove Equipment"
          resetField={() => resetField({ equipmentTypes: [] })}
        >
          {props.carriersFilter.equipmentTypes.map((equipmentType) => (
            <div key={equipmentType.key}>{equipmentType.text}</div>
          ))}
        </CarrierFilterLabelPopup>
      ) : null}

      {props.carriersFilter.trailerTypes.length ? (
        <CarrierFilterLabelPopup
          labelText="Trailer Type"
          title="Remove Trailer Types"
          resetField={() => resetField({ trailerTypes: [] })}
        >
          {props.carriersFilter.trailerTypes.map((trailerType) => (
            <div key={trailerType.key}>{trailerType.text}</div>
          ))}
        </CarrierFilterLabelPopup>
      ) : null}

      {props.carriersFilter.preferredServiceAreas.length ? (
        <CarrierFilterLabelPopup
          labelText="Preferred Area"
          title="Remove Preferred Areas"
          resetField={() => resetField({ preferredServiceAreas: [] })}
        >
          {props.carriersFilter.preferredServiceAreas.map((preferredArea) => {
            const [country, state] = preferredArea.split(",");

            return (
              <div key={preferredArea}>
                <Flag name={country.toLowerCase() as FlagNameValues} />
                {state}
              </div>
            );
          })}
        </CarrierFilterLabelPopup>
      ) : null}

      {props.carriersFilter.attributes.length ? (
        <CarrierFilterLabelPopup
          labelText="Attributes"
          title="Remove Attributes"
          resetField={() => resetField({ attributes: [] })}
        >
          {props.carriersFilter.attributes.map((attribute) => (
            <div key={attribute.key}>{attribute.text}</div>
          ))}
        </CarrierFilterLabelPopup>
      ) : null}
    </div>
  );
};

export default CarrierFilterLabels;
